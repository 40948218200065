import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import ImageCarousel from "../utils/ImageCarousel";

import {
  newsText,
  newsImageWrapper,
  newsHeader,
  newsWrapper,
} from "../css/newstemplate.module.css";

const Post = ({ data }) => {
  const {
    strapiBlog: { blogTitle, blogText, mainImage, blogOtherImageFile },
  } = data;

  return (
    <>
      <div className={newsWrapper}>
        <div className={newsHeader}>
          <h1>News</h1>
          <p>Latest from Pannal Village Hall</p>
        </div>

        <h2>{blogTitle}</h2>
        <div className={newsImageWrapper}>
          {blogOtherImageFile.length > 0 ? (
            <ImageCarousel
              roomImages={[mainImage, ...blogOtherImageFile]}
              RoomTitle={blogTitle}
            />
          ) : (
            <GatsbyImage
              image={mainImage.childImageSharp.gatsbyImageData}
              alt={blogTitle}
              imgStyle={{ objectFit: "cover" }}
              style={{ height: "100%", maxHeight: "539px" }}
            />
          )}
        </div>
        <div className={newsText}>
          <ReactMarkdown source={blogText} />
        </div>
      </div>
    </>
  );
};

export default Post;

export const query = graphql`
  query post($slug: String!) {
    strapiBlog(slug: { eq: $slug }) {
      id
      slug
      blogTitle
      blogText
      mainImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      blogOtherImageFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;
